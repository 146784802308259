<template>
  <div>
    <title>Frequently Asked Questions</title>
    <v-container class="mt-10 lp-tertiary-plum" fluid>
      <v-container>
        <v-card
            color="var(--accent)"
            class="my-10 pa-5"
            elevation="5"
        >
          <LearningPlusLogo :height="35" class="mb-5" />

          <div class="pp-hr pp-center mb-7"></div>
          <h1 class="text-md-h2 text-h5 font-weight-bold text-center primary--text">
            Frequently Asked Questions
          </h1>

          <h2 class="text-center pa-5 text-h6 ">
            This is our page dedicated to all frequently asked questions about LearningPlus in general. Here you can explore answers to popular queries, or you can contact us directly by email.
          </h2>
        </v-card>
      </v-container>
    </v-container>

    <v-container class="mt-15 px-5 px-xl-16">
      <v-row
          v-for="frequentlyAskedQuestion in frequentlyAskedQuestions"
          :key="frequentlyAskedQuestion"
      >
        <v-card
            class="mx-auto rounded-xl pa-md-3 ma-3"
            width="100%"
        >
          <v-list>
            <v-list-group
                prepend-icon="mdi-help-box"
            >
              <template v-slot:activator>
                <v-list-item
                    class="font-weight-medium text-subtitle-2 text-md-h6 px-0"
                    v-text="frequentlyAskedQuestion.title">
                </v-list-item>
              </template>

              <v-list-item>
                <v-list-item-content>
                  <div class="text-subtitle-1">
                    <v-divider class="mb-5"/>
                    <p
                        class="px-lg-16"
                        v-text="frequentlyAskedQuestion.text"></p>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-row>
    </v-container>

    <v-container class="mt-10 text-center">
      <div class="text-h6 primary--text mb-5">
        Still have questions?
      </div>

      <ContactUsButton />

      <v-divider />
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import LearningPlusLogo from "@/components/logos/LearningPlusLogo";
import ContactUsButton from "@/components/buttons/ContactUsButton";

export default {
  name: "FAQs",
  metaInfo: {
    title: 'LearningPlus FAQs',
    meta:[
      { description: 'This is our page dedicated to all frequently asked questions about LearningPlus in general. Here you can explore answers to popular queries, or you can contact us directly by email. ' },
      { image: 'https://www.learningplus.co.uk/images/shades/splash-bg-1.jpg' },
    ],
  },
  components: {ContactUsButton, LearningPlusLogo},
  data: () => ({
    show: false,
  }),
  computed: {
    ...mapGetters(['frequentlyAskedQuestions']),
  },
};
</script>

<style scoped>

.lp-tertiary-plum {
  background-image: url("/images/shades/about-bg-1.jpg");
  background-size: cover;
}

.v-list-item__icon {
  margin: 0!important;
}

</style>
